import * as PIXI from "pixi.js";

const canvas_spacer = document.querySelector(".canvas-spacer");
const main_canvas = document.getElementById("seb-canvas");
const playground = document.getElementById("seb-playground");


if(playground && main_canvas){
  
  PIXI.Assets.add({alias: "myShader", src:"./js/shaders/my_raymarching_piece.txt"})
  
  const my_shader = await PIXI.Assets.load("myShader");
  
  const uniforms = {
    time: 0,
    mouse: new PIXI.Point(),
    scrollOffset: 0
  }
  
  const PixiApp = new PIXI.Application({view: main_canvas, resizeTo: playground})
  
  const Raymarcher = new PIXI.Filter(PIXI.Filter.defaultVertexSrc, my_shader, uniforms)
  
  const background = new PIXI.Sprite(PIXI.Texture.WHITE)
  
  background.width = PixiApp.screen.width;
  background.height = PixiApp.screen.height;
  
  background.filters = [Raymarcher]

  adjustRaymarchingZoomBasedOnOffsetToTop();
  
  document.addEventListener("scroll", adjustRaymarchingZoomBasedOnOffsetToTop);

  function adjustRaymarchingZoomBasedOnOffsetToTop() {
    const buffer_bottom = canvas_spacer.getBoundingClientRect().bottom;
    const maxOffset = buffer_bottom - document.body.getBoundingClientRect().top;
    const normalized_offset = buffer_bottom != 0.0 ? Math.min(Math.max(buffer_bottom / maxOffset, 0), 1) : 0.0;
    Raymarcher.uniforms.scrollOffset = normalized_offset;
  }
  
  PixiApp.stage.addChild(background)
  
  let time = 0;
  
  const animation = (delta) => {
    // Update the PIXI.Filter parameters to create an animation effect
    // filter.hue(delta * 0.1);
    time += delta;
    Raymarcher.uniforms.time = time / 60;
  };

  const handleVisibility = () => {
    const bounds = background.getBounds();
    const isVisible = bounds.x < PixiApp.screen.width &&
    bounds.y < PixiApp.screen.height &&
    bounds.x + bounds.width > 0 &&
    bounds.y + bounds.height > 0;
    
    if (isVisible) {
      background.filters = [Raymarcher];
    } else {
      background.filters = null;
    }
  }
  
  PixiApp.ticker.add(animation);
  PixiApp.ticker.add(handleVisibility);
  
  PixiApp.stage.hitArea = PixiApp.screen;
  PixiApp.stage.eventMode = 'static';
  
  PixiApp.stage.on('pointermove', (event) =>
  {
      Raymarcher.uniforms.mouse.copyFrom(event.global);
  });
}
