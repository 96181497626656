import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import "./modules/scroll_text_snippet";
import "./modules/playground/index.js";
import {isMobile} from "./library/utils.js";
import {circle_elements_around_element} from "./library/dom.js";
// loads the Icon plugin
UIkit.use(Icons);

manageElementVisibility();
// setTimeout(initHTMLGL, 3000);

  Array.from(document.getElementsByClassName("js-circle-around")).forEach(e => {
    circle_elements_around_element(e, 0);
  })

console.log(window.navigator.userAgent);
if(isMobile()){
  if(window.navigator.userAgent){
    setTimeout(() => {
    
      UIkit.scroll(document.body).scrollTo("#scroll-to-mobile");
    }, 600);
  }
}

//Look into rasterizeHTML instead + PIXI or ThreeJS https://www.npmjs.com/package/rasterizehtml
function initHTMLGL(){
  HTMLGL.stage.interactive = true;
  const elementGL = HTMLGL.elements[0];
  if(elementGL){
    console.log(window.HTMLGL, PIXI);
    // const asciiFilter = new PIXI.filters.AsciiFilter();
    // elementGL.sprite.filters = [];
    window.requestAnimationFrame( animate )
    function animate() {
      HTMLGL.renderer.render(HTMLGL.stage);
      window.requestAnimationFrame( animate );
    }
  }
}



function manageElementVisibility(){
  const scroll_back_up = document.getElementById('scroll-back-to-top');
  const scroll_down = document.getElementById('scroll-down');
  visibilityBasedOnVerticalPosition();
  document.addEventListener("scroll", e => {
    visibilityBasedOnVerticalPosition();
  })

  function visibilityBasedOnVerticalPosition(){
    if(scroll_back_up !== undefined && scroll_back_up !== null){
      scroll_back_up.classList.toggle("uk-hidden", !(window.scrollY > 0));
    }
    if(scroll_down !== undefined && scroll_down !== null){
      scroll_down.classList.toggle("uk-hidden", window.scrollY > 0);
    }
  }

}