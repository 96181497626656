

export function circle_elements_around_element(circle_container){
  const {target, spread, padding, start} = circle_container.dataset;
  const elements = Array.from(circle_container.children);
  circle_container.style.position = "relative";
  elements.forEach( (element, i) => {
    element.style.position = "absolute";
    element.style.transform = "translate(-50%, -50%)";
    element.style.width = 22 + "px";
    element.style.height = 22 + "px";
  })
  const targetElement = document.getElementById(target);
  const target_center = get_element_center(targetElement);
  const container_center = get_element_center(circle_container);
  const offset = {x: target_center.x - container_center.x, y: target_center.y - container_center.y};
  circle_container.style.left = offset.x + "px";
  circle_container.style.top = offset.y + "px";
  const radius = targetElement.getBoundingClientRect().width / 2;
  distribute();
  
  function distribute(){
    elements.forEach( (element, i) => {
      const start_on = ( (1 - parseInt(get_seconds()) / 30) * 360);
      console.log({start_on, seconds: get_seconds()})
      const degrees = start_on + i * parseInt(spread);
      const pi_position = degrees / 360 * Math.PI;
      element.style.left = Math.sin(pi_position) * (radius + parseInt(padding)) + "px";
      element.style.top = Math.cos(pi_position) * (radius + parseInt(padding)) + "px";
    })
  }

}

function get_seconds () {
  return (new Date()).getSeconds()
}

function get_element_center(element){
  const rect = element.getBoundingClientRect();
  const center = {
    x: rect.left + rect.width / 2,
    y: rect.top + rect.height / 2
  }
  return center
}

function show_coords(x, y, color){
  const point = document.createElement("div");
  point.style.width = point.style.height = "2px";
  point.style.backgroundColor = color;
  point.style.position = "absolute";
  point.style.top = y + "px";
  point.style.left = x + "px";
  point.style.zIndex = "999";
  document.body.appendChild(point);
}

function outline_rect(rect){
  show_coords(rect.left, rect.top, "red");
  show_coords(rect.left, rect.bottom, "red");
  show_coords(rect.right, rect.bottom, "red");
  show_coords(rect.right, rect.top, "red");
  show_coords(rect.left + rect.width / 2, rect.top + rect.height / 2, "white");
}