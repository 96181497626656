
const seb_badges = document.getElementById('seb-badges');

if(seb_badges){

  const list_of_badges = seb_badges.querySelectorAll("li");
  
  const list_of_words_for_badges = [
    "language",
    "imagination",
    "code"
  ]
  
  const trace = value => {console.log(value); return value;}
  
  const cycle = (offset) => {
    const n = list_of_words_for_badges.length;
    list_of_badges.forEach( (badge, index) => {
      const i = (index + offset) % n;
      badge.innerHTML = list_of_words_for_badges[i];
    })
  }
  
  const ticker = (time, targetFunction, start = 0) => {
    const ticks = [];
    tick(time, targetFunction, ticks, start);
    return ticks;
  }
  
  const tick = (time, targetFunction, ticks, start = 0) => {
    return setTimeout(() => {
      targetFunction(start);
      ticks.push(tick(time, targetFunction, ticks, start + time));
    }, time);
  }
  
  const start_cycle = speed => ticker(speed, time => cycle(Math.round(time > 0 ? (time / speed) : 0)))
  const interval = 100;
  let cycles = start_cycle(interval);
  
  list_of_badges.forEach( (badge, index) => {
    badge.addEventListener("click", () => {
      if(badge.classList.contains("active")){
        badge.classList.remove("active");
        cycles = start_cycle(interval);
      } else {
        cycles.forEach(cycle_id => clearInterval(cycle_id));
        console.log("Stop Cycle!");
        badge.classList.add("active");
        list_of_badges.forEach((badge, index2) => {
          if(index2 != index){
            if(badge.classList.contains("active")){
              badge.classList.remove("active");
            }
          }
        })
      }
    })
  })
}
